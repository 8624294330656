type BlogAuthors =
  | "leifastrand"
  | "lillisalo"
  | "marcushellberg"
  | "mattitahvonen"
  | "mikaelsukoinen"
  | "artursignell"
  | "tarekoraby"
  | "lawrencedlockhart";

export interface IBlogAuthorData {
  name: string;
  avatar: string;
  about: string;
  twitter?: string;
  linkedin?: string;
}

export type IBlogAuthors = {
  [key in BlogAuthors]: IBlogAuthorData;
};

export const authors: IBlogAuthors = {
  leifastrand: {
    name: "Leif Åstrand",
    twitter: "",
    linkedin: "",
    about:
      "Leif Åstrand keeps an eye on the overall architecture of the Vaadin platform. He knows a thing or two about how Vaadin, Web Components, and the internet works.",
    avatar: "leifastrand.jpg",
  },
  lillisalo: {
    name: "Lilli Salo",
    twitter: "",
    linkedin: "",
    about:
      "Lilli joined Vaadin in 2021 after delivering content for various international SaaS startups. She enjoys the creative challenge of transforming complicated topics into clear and concise written material that provide value to the reader.",
    avatar: "lillisalo.jpg",
  },
  marcushellberg: {
    name: "Marcus Hellberg",
    twitter: "marcushellberg",
    linkedin: "",
    about:
      "Marcus is the VP of Developer Relations at Vaadin. His daily work includes everything from writing blogs and tech demos to attending events and giving presentations on all things Vaadin and web-related.",
    avatar: "marcushellberg.jpg",
  },
  mattitahvonen: {
    name: "Matti Tahvonen",
    twitter: "MattiTahvonen",
    linkedin: "",
    about:
      "Matti Tahvonen has a long history in Vaadin R&D: developing the core framework from the dark ages of pure JS client side to the GWT era and creating number of official and unofficial Vaadin add-ons. His current responsibility is to keep you up to date with latest and greatest Vaadin related technologies.",
    avatar: "mattitahvonen.png",
  },
  mikaelsukoinen: {
    name: "Mikael Sukoinen",
    twitter: "",
    linkedin: "",
    about:
      "Mikael has a passion for writing text, code and music. He’s currently utilising his skills to explain difficult concepts in plain language at <a class='link' href='https://vaadin.com' target='_blank' rel='noopener'>Vaadin Ltd</a>.",
    avatar: "mikaelsukoinen.jpg",
  },
  artursignell: {
    name: "Artur Signell",
    twitter: "artursignell",
    linkedin: "",
    about:
      "Artur Signell works as CTO at Vaadin. He earlier lead the Vaadin products team and was responsible for all Vaadin products. Currently he is focusing on technology and research.",
    avatar: "artursignell.jpg",
  },
  tarekoraby: {
    name: "Tarek Oraby",
    twitter: "",
    linkedin: "",
    about:
      "Tarek is a Product Manager at Vaadin. His daily work is a blend of talking to users, planning, and overseeing the evolution of Vaadin Flow and Hilla, ensuring they consistently delight our developer community.",
    avatar: "tarekoraby.jpg",
  },
  lawrencedlockhart: {
    name: "Lawrence Lockhart",
    twitter: "lawrencedcodes",
    linkedin: "",
    about:
        "Lawrence is a Developer Advocate with Vaadin.  He works with the Hilla and Flow products and enjoys tech conversations that lead to developer wins.",
    avatar: "lawrence.jpeg",
  },
};

export function getAuthor(authorKey: string) {
  return authors.hasOwnProperty(authorKey)
    ? authors[authorKey as BlogAuthors]
    : null;
}
