import React, { FunctionComponent } from "react";
import { graphql, Link } from "gatsby";
import { BlogPostMeta, MainLayout, Seo } from "../../components";
import { IPageInfo, ISiteMetadata, IFrontmatter } from "../../types";
import { getAuthor } from "../../authors";

interface IBlogNode {
  id: string;
  slug: string;
  excerpt: string;
  frontmatter: IFrontmatter;
}

interface IEdge {
  node: IBlogNode;
}

type Props = {
  data: {
    allMdx: {
      edges: IEdge[];
      pageInfo: IPageInfo;
    };
    site: {
      siteMetadata: ISiteMetadata;
    };
  };
};

export const query = graphql`
  query BlogPosts {
    allMdx(
      limit: 9999 # Until pagination is implemented
      skip: 0
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          slug
          excerpt(pruneLength: 300)
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            author
          }
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
        totalCount
      }
    }
  }
`;

const BlogIndex: FunctionComponent<Props> = ({ data }) => {
  return (
    <MainLayout>
      <Seo
        title="Hilla Blog"
        description="Ideas, news and insights about Hilla"
      />

      <div className="max-w-3xl pt-md md:pt-xl pb-lg container">
        <section className="">
          <h1 className="text-5xl md:text-6xl lg:text-[4.5rem] tracking-[-2px]">
            Hilla Blog
          </h1>
          <p className="mt-4 text-lg">
            Developer’s source of truth for Hilla development.
          </p>
        </section>

        {data.allMdx.edges.map((post) => {
          const author = getAuthor(post.node.frontmatter.author);
          return (
            <article className="mt-28" key={post.node.id}>
              <BlogPostMeta
                author={author}
                frontmatter={post.node.frontmatter}
              />

              <h2 className="mt-2 font-header font-semibold text-3xl hover:text-blue-400 transition-colors duration-150">
                <Link to={`/blog/${post.node.slug}`}>
                  {post.node.frontmatter.title}
                </Link>
              </h2>

              <div className="mt-4">{post.node.excerpt}</div>

              <Link
                to={`/blog/${post.node.slug}`}
                className="mt-4 inline-flex items-center text-blue-400 hover:text-blue-300 transition-colors ease-linear duration-150 group"
              >
                <span>Read more</span>
                <i className="ml-1 group-hover:translate-x-1 leading-none transition-transform ease-out duration-75 las la-arrow-right"></i>
              </Link>
            </article>
          );
        })}
      </div>
    </MainLayout>
  );
};

export default BlogIndex;
